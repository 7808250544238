.delete-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;

  .delete-popup {
    background-color: #fff;
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .delete-message {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .delete-buttons {
      display: flex;
      justify-content: center;
      margin-top: 16px;

      button {
        margin: 0 8px;
        padding: 8px 16px;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;

        &:nth-child(1) {
          background-color: #f44336;
          color: #fff;
        }

        &:nth-child(2) {
          background-color: #ccc;
          color: #000;
        }
      }
    }
  }
}
