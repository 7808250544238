.setting-panel{
  h4{
    font-family: var(--roboto);
  }
/*
======================
custom select buttons
======================
*/
/*the container must be positioned relative:*/
.option-container{
  position: relative;
  .selector {
    padding: 16px 32px;
    font-family: var(--roboto);
    text-align: center;
    border: 1px solid var(--secondary-text-color);
    color: var(--secondary-text-color);
    font-size: 18px;
    margin-bottom: 2rem;
    position: relative;
    user-select: none;
    cursor: pointer;
    font-weight: 700;
    .arrow {
      position: absolute;
      width: 0;
      height: 0;
      border: 6px solid black;
      right: 16px;
      border-color: var(--secondary-text-color) transparent transparent;
      top: 25px;
    }
  }
  .custom-options {
    position: absolute;
    width: 100%;
    z-index: 99;
    background-color: var(--clr-white);
    padding: 10px;
    max-height: fit-content;
    overflow: hidden;
    overflow: auto;
    transition: all 1s ease-in-out;
    input[type=radio]{
      display: none;
    }
    label {
      padding: 16px 32px;
      display: block;
      text-align: center;
      font-family: var(--roboto);
      border: 1px solid var(--secondary-text-color);
      color: var(--secondary-text-color);
      margin-bottom: 1rem;
      cursor: pointer;
      font-size: 18px;
      font-weight: 700;
    }
  }
}
/*
        ======================
        ends custom select buttons
        ======================
        */
}