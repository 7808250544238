.books-container{
          margin-top: 70px;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          justify-content: center;
          align-items: center;
          padding: 40px;
          .bookRaw{
                    position: relative;
                    background: var(--clr-primary-10);
                    width: 300px;
                    overflow: hidden;
                    .image{
                              img{
                                        width: 300px;
                                        height: 500px;
                              }
                    }
                    .book-content{
                              display: flex;
                              flex-direction: column;
                              padding: 10px;
                              .title{
                                        display: flex;
                                        justify-content: center;

                                        h4{
                                                  display: inline;
                                                  font-size: 20px;
                                                  text-align: center;
                                                  color: var(--clr-black);
                                                  width: fit-content;
                                                  max-width: 100%;
                                                  word-wrap: wrap;
                                                  font-family: "Jameel Noori Nastaleeq","JNNastaleeq" !important;
                                                  font-size: 20px;
                                                  font-style: bold;
                                        }
                              }
                              .profile{
                                        display: flex;
                                        align-items: center;
                                        gap: 20px;
                                        .left{
                                                  display: inline-flex;
                                                  justify-content: center;
                                                  align-items: center;
                                                  img{
                                                            width: 40px;
                                                            border-radius: 100%;
                                                  }
                                        }
                                        .right{
                                                  span{
                                                            display: block;
                                                            font-size: 14px;
                                                            padding: 0;
                                                            margin: 0;
                                                            color: var(--secondary-text-color);
                                                            margin-right: 10px;
                                                  }
                                                  .title{
                                                            display: block;
                                                            font-style: bold;
                                                  }
                                        }
                              }
                    }
                    .share{
                              position: absolute;
                              cursor: pointer;
                              right: 10px;
                              bottom: 10px;
                    }
          }
}
@media screen and (max-width: 500px){
          .books-container{
                    padding: 10px;
          }
}