.input-container{
          .container {
                    position: relative;
                    width: 100%;
                    padding: 0;
          
                    input {
                              width: 100%;
                              background: transparent;
                              outline: none;
                              border: none;
                              color: #222;
                              padding: 8px;
                              &:valid~label,
                              &:focus~label {
                                        color: var(--primary-2);
                                        transform: translateY(-15px);
                                        font-size: .6rem;
                              }
                    }
          
                    label {
                              position: absolute;
                              left: 0;
                              top: 0;
                              color: rgba(105, 105, 105, 0.7);
                              text-transform: uppercase;
                              pointer-events: none;
                              transition: all .5s;
                              font-weight: bold;
                    }
          
                    i {
                              position: absolute;
                              left: 0;
                              bottom: 0;
                              width: 100%;
                              height: 2px;
                              background: var(--primary-2);
          
                              :before {
                                        content: '';
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        background: linear-gradient(90deg, #ff1b69, #ff0, #2196f3, #9c27b0, #ff1b69);
                                        animation: animate 3s linear infinite;
                              }
                    }
          }
}