.single-option{
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 12px;
          label{
                    color: var(--primary-2);
          }
          input{
                    accent-color: var(--primary-2);
                    position: relative;
                    margin-top: 6px;
          }
}