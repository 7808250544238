.context-menu-container {
  position: absolute;
  display: none;
  .context-menu {
    display: flex;
    flex-direction: column;
    padding: 10px;
    span {
      display: flex;
      gap: 10px;
      align-items: center;
      cursor: pointer;
    }
  }
}
