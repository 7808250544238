.background {
  background: var(--clr-primary-5);
  width: 100vw;
  height: 100vh;

  .front-div {
    background: var(--clr-white);
    width: 80vw;
    height: 100vh;
    padding-left: 10vw;
    padding-top: 100px;
    position: relative;

    .img-abs {
      position: absolute;
      width: calc(14vw);
      right: -8.5vw;
      top: 25%;
    }

    .error-message {
      background: var(--clr-primary-10);
      color: var(--clr-red-dark);
      padding: 10px 30px;
      border: 2px solid var(--clr-red-dark);
      border-radius: var(--radius);
      display: flex;
      gap: 10px;
      margin-bottom: 30px;
    }

    .form-container {
      margin-top: 100px;
    }

    form {
      span {
        position: relative;

        input {
          display: block;
          padding: 12px 15px;
          padding-top: 25px;
          outline: none;
          border: double var(--clr-primary-5) 1.5px;
          font-size: 12px;
          width: 100%;
          margin-bottom: 30px;
          background: transparent;
        }

        label {
          position: absolute;
          left: 15px;
          top: 7px;
          color: var(--clr-primary-5);
          background: transparent;
          font-size: 12px;

        }
      }

      button.login:hover {
        box-shadow: var(--dark-shadow);
      }

      button {
        padding: 12px 24px;
        background: var(--clr-white);
        border: 1px solid var(--clr-primary-5);
        color: var(--clr-primary-5);
        width: 100%;
        cursor: pointer;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          font-size: 20px;
          margin-right: 10px;
          position: relative;

        }
      }

      button.login {
        width: 100%;
        padding: 12px;
        background: var(--clr-primary-5);
        color: white;
        border: none;
        border-radius: 5px;
        margin-bottom: 20px;
        box-shadow: var(--light-shadow);
      }

      h4 {
        text-align: center;
        margin: 0;
        margin-bottom: 20px;
      }

    }

    .headings-container {
      width: fit-content;

      h3 {
        text-align: left;
      }
    }
  }
}

@media screen and (max-width:792px) {
  .background {
    .front-div {
      width: 100vw;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .img-abs {
        display: none;
      }

      .headings-container {
        margin: 0;
        padding: 60px 30px;
      }
    }
  }
}