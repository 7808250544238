.admin-books-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px;
  align-items: center;
  gap: 20px;
  .book {
    width: 100%;
    border-radius: 6px;
    background-color: var(--clr-primary-10);
    border-bottom: 3px solid var(--clr-primary-9);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .info {
      display: grid;
      grid-template-columns: 70px 1fr 1fr;
      align-items: center;
      gap: 30px;
      span{
          color: var(--clr-black);
          font-weight: 700;
          font-style: bold;
      }
      img{
          height: 60px;
      }
      
    }
    .actions {
      display: flex;
      justify-content: center;
      align-items: center;
      h4 {
        display: flex;
        gap: 5px;
        margin: 5px;
        color: var(--clr-black);
        &.download {
          cursor: pointer;
          font-family: var(--roboto);
          svg {
            position: relative;
            top: -1px;
          }
        }
      }
      span{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}
