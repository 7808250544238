.preloader{
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 40;
          background-color: #fff;
          display: grid;
          place-items: center;
      }
      .plc{
          position: relative;
          width: 15rem;
          height: 15rem;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
      }
      .plro{
          position: absolute;
          width: 15rem;
          height: 15rem;
          border-radius: 50%;
          border-right: .3rem solid black;
          animation: rot 2s linear infinite;
      }
      @keyframes rot {
          0%{
              transform: rotate(0deg);
          }
          100%{
              transform: rotate(360deg);
          }
          
      }
      .plro h1{
          margin-top: 50%;
          margin-left: 50%;
      }