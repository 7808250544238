.single-book {
  img {
    margin-bottom: 10px;
    border-radius: 5px;
  }
  div {
    display: flex;
    gap: 10px;
    .count-container {
      background: var(--clr-primary-5);
      text-align: center;
      padding: 0;
      margin: 0;
      font-size: 10px;
      color: white;
      padding: 3px;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      padding-bottom: 10px;
      position: relative;
      height: 45px;

      &::before {
        content: "";
        background: var(--clr-primary-10);
        width: 0;
        height: 0;
        border: 13px solid var(--clr-primary-10);
        border-color: transparent transparent var(--clr-primary-10);
        background-color: transparent;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
      }

      span {
      }
    }
  }
}
