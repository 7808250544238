@font-face {
  font-family: NHuda;
  src: url("../fonts/noorehuda\ Regular.ttf");
}
@font-face {
  font-family: MehrNastaliqWeb;
  src: url("../fonts/MehrNastaliqWebRegular.ttf");
}
@font-face {
  font-family: JNNastaleeq;
  src: url("../fonts/Jameel\ Noori\ Nastaleeq\ 2.ttf");
}
// importing roboto and poppins font from google fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto:wght@700&display=swap')