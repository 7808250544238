.content{
          max-height: fit-content;
          overflow-y: scroll;
          overflow-x: hidden;
          .bookRaw{
                    display: flex;
                    justify-content: left;
                    gap: 20px;
                    align-items: center;
                    padding: 20px;
                    border-bottom: 2px solid var(--primary-2);
                    img{
                              width: 50px;
                              cursor: pointer;
                    }
                    div{
                              h4{
                                        margin: 0;
                                        cursor: pointer;
                                        color: var(--secondary-text-color);
                                        font-style: bold;
                                        cursor: pointer !important;
                                        
                                        
                              }
                              span{
                                        font-size: 12px;
                                        padding-left: 0;
                                        color: var(--secondary-text-color);
                              }
                    }
          }
}