.single-page-container {
          .page-top {

                    height: auto;
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    gap: 30px;
                    cursor: pointer;
                    border: 1px solid var(--clr-black);
                    justify-content: center;
                    h3{
                              position: relative;
                              margin-top: 20px;
                    }

                    .rotate {
                              border: 2px solid var(--clr-black);
                              background-color: var(--clr-primary-9);
                              width: 70px;
                              height: 70px;
                              display: inline-flex;
                              justify-content: center;
                              align-items: center;
                              flex-direction: column;
                              transform: rotate(45deg);

                              

                              h4 {
                                        margin: 0;
                                        padding: 0;
                                        transform: rotate(-45deg);
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        gap: 10px;
                                        flex-direction: column;
                                        span {
                                                  font-size: 10px;
                                        }

                              }
                    }
          }
          .page-bottom-container{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    .page-bottom{
                              display: grid;
                              grid-template-columns: 2fr 8fr;
                              border: 1px solid var(--clr-black);
                              cursor: pointer;
                              .left{
                                        
                                        h4{
                                                  background-color: var(--clr-primary-9);
                                                  height: 100%;
                                                  display: flex;
                                                  justify-content: center;
                                                  align-items: center;
                                                  padding: 0 10px;
                                        }
                              }
                              .right{
                                        width: 100%;
                                        direction: rtl;
                                        padding: 10px;
                                        text-align: justify;
                              }
                    }
                    
          }
}