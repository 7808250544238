.editor-header {
  .buttons-container {
    position: fixed;
    color: var(--clr-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px;
    top: 70px;
    z-index: 10;
    left: 0;
    right: 0;
    background: var(--dark1);

    .left-buttons {
      display: inline-flex;
      align-items: center;
      gap: 10px;
      select{
       
          &::-webkit-scrollbar{
            display: none;
          }
          option{
            background: var(--dark1);

          }
      }

      .style-button,
      select,
      button {
        background: transparent;
        color: var(--clr-white);
        border: none;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        cursor: pointer;

        svg {
          font-size: 20px;
          position: relative;
          top: 5px;
          font-weight: 700;
        }
      }
    }

    .slide-panel {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 20px 30px;
      box-shadow: var(--dark-shadow);
      box-shadow: var(--shadow2);
      height: 0vh;
      left: 0;
      bottom: -30px;
      background: var(--clr-white);
      transition: all 700ms ease-in-out;
      overflow: hidden;
      height: calc(100vh - 134px);
      position: fixed;
      top: 136px;
      left: 40px;
      overflow: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
      &.setting-panel{
        left: unset;
        right: 0;
        width: 400px;
      }
      &.hide {
        height: 0vh;
        padding: 0px;
        width: 0;
      }
      
      .style {
        color: var(--clr-white);
        background: transparent;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        gap: 10px;
        padding: 10px;

        .demo {
          width: 160px;
          border: 2px solid var(--clr-black);
          padding: 10px;
        }
      }
    }
    .right-button {
      position: relative;
      display: inline-flex;
      gap: 10px;
      align-items: center;

      .dropDownButtons {
        position: relative;
        font-size: 18px;
        cursor: pointer;
        border: 2px solid var(--clr-black);
        padding: 8px 18px;
        border-radius: 25px;
        letter-spacing: 2px;
        width: 170px;
        display: inline-flex !important;
        justify-content: center;
        align-items: center;

        svg {
          font-size: 17px;
          margin-right: 10px;
        }

        .dropDown {
          position: absolute;
          left: 0;
          padding: 10px 20px;
          background: var(--clr-white);
          height: 100px;
          top: 50px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 170px;
          overflow: hidden;
          box-shadow: var(--dark-shadow);
          flex-direction: column;
          transition: all 500ms ease-in-out;
          z-index: 90;

          &.hide {
            height: 0;
            padding: 0;
          }

          button {
            background-color: transparent;
            text-align: left;
            display: flex;
            width: 100%;
            border: none;
            font-size: 18px;
            letter-spacing: 2px;
            padding: 10px 0;
            cursor: pointer;

            svg {
              font-size: 18px;
            }
          }
        }
      }
      .setting-button{
        cursor: pointer;
      }
      .saving-msg {
        position: absolute;
        right: 0;
        bottom: -60px;
        padding: 6px 14px;
        background-color: black;
        border-radius: 5px;
        z-index: 101;
      }
    }
  }
}

.App {
  position: relative;
  top: 110px;
  max-width: 100vw !important;
  padding: 20px 40px;
  
  textarea {
    display: block;
    margin: 10px 0;
    margin-bottom: 40px;
    width: 100%;
    resize: none;
    border: none;
    border-radius: 5px;
    min-height: 300px;
    padding: 20px;
  }
  .context-menu{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    position: absolute;
    background-color: var(--dark1);
    padding: 0px;
    width: 0px;
    background-color: var(--clr-white);
    box-shadow: var(--dark-shadow);
    // if you looking padding then checkout js function file 
    height: 0px;
    overflow: scroll;
    transition: all 500ms ease-in-out;
    &::-webkit-scrollbar{
      display: none;
    }
    button{
      background: transparent;
      border: none;
      border: 2px solid black;
      display: inline;
      cursor: pointer;
      padding: 16px;
    }
  }
  #myTa {
    direction: rtl;


    overflow-y: scroll;

    &.rtl {
      direction: rtl;
    }
    
  }
}