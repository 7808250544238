.bottom-bar{
          z-index: 200;
          position: fixed;
          justify-content: space-between;
          padding: 0 10px;
          align-items: center;
          background-color: var(--clr-white);
          bottom: 0;
          left: 0;
          right: 0;
          display: none;
          .bottom-button-container{
                    display: flex;
                    padding: 10px;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    color: var(--secondary-text-color);
                    &.active{
                              color: var(--clr-black);
                    }
                    span{
                             
                              svg{
                                        font-size: 20px;
                              }
                    }
          }
}
@media screen and (max-width: 944px){
.bottom-bar{
display: flex;
}
}         