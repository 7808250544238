.dashboard-single-book{
          display: flex;
          justify-content: left;
          align-items: center;
          gap: 30px;
          img{
                    width: 150px;
                    margin-bottom: 100px;
          }
          .book-content{
                    margin-bottom: 100px;
                    span{
                              margin-right: 20px;
                              letter-spacing: 3px;
                    }
                    h4{
                              display: inline;
                    }
          }
}
@media screen and (max-width: 944px) {
          .dashboard-single-book{
                    display: grid;
                    grid-template-columns: 100px 1fr;
                    place-items: initial;
                    gap: 15px;
                    img{
                              width: 100px;
                    }
                    .book-content{
                              padding: 10px 0;
                              span{
                                        font-size: 12px;
                              }
                              h4{
                                        font-size: 16px;
                              }
                    }
          }
}