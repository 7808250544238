#check:checked {
    ~.sidebar {
        left: -185px;

        .sidebar a span {
            display: none;
        }

        a {
            font-size: 20px;
            margin-left: 165px;
            width: 100%;
        }

        .profile_info {
            display: none;
        }
    }

    &~.content {
        margin-left: 60px;
    }
}

#check {
    display: none;
}

@media screen and (max-width: 944px) {
    #check:checked {
        ~.sidebar {
            left: 0;

           a span {
                display: inline;
            }

            a {
                font-size: 20px;
                margin-left: 0px;
                width: 100%;
            }

            .profile_info {
                display: flex;
            }
        }

        &~.content {
            margin-left: 60px;
        }
    }

    .sidebar {
        left: calc(-100vw + 60px);
        a span {
            display: none;
        }
        a {
            font-size: 20px;
            margin-left: calc(100vw - 80px);
            width: 100%;
        }

        .profile_info {
            display: none;
        }

    }
}