/* inspired from this svgbackgrounds.com/ */
.button {
  cursor: pointer;
          width: fit-content;
          display: flex;
          padding: 0.6em 1.1em;
          gap: 0.4rem;
          font-weight: bold;
          border-radius: 30px;
          text-shadow: 2px 2px 3px rgb(136 0 136 / 50%);
          background: linear-gradient(15deg, #880088, #aa2068, #cc3f47, #de6f3d, #f09f33, #de6f3d, #cc3f47, #aa2068, #880088) no-repeat;
          background-size: 300%;
          background-position: left center;
          transition: background .3s ease;
          color: #fff;
        }
        
        .button:hover {
          background-size: 320%;
          background-position: right center;
        }
        
        .button:hover svg {
          fill: #fff;
        }
        
        .button svg {
          width: 23px;
          fill: #f09f33;
          transition: .3s ease;
        }