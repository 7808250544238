.sidebar {
          z-index: 1;
          top: 0;
          background: #2f323a;
          margin-top: 70px;
          padding-top: 30px;
          position: fixed;
          left: 0;
          width: 250px;
          height: calc(100% - 70px);
          transition: 0.5s;
          transition-property: left;
          overflow-y: auto;
          overflow-x: hidden;

          .profile_info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .profile_image {
                              width: 100px;
                              height: 100px;
                              border-radius: 100px;
                              margin-bottom: 10px;
                    }

                    h4 {
                              color: #ccc;
                              margin-top: 0;
                              margin-bottom: 20px;
                    }
          }

          a {
                    color: #fff;
                    display: block;
                    width: 100%;
                    line-height: 60px;
                    text-decoration: none;
                    padding-left: 40px;
                    box-sizing: border-box;
                    transition: 0.5s;
                    transition-property: background;
                    &:hover {
                              background: #19B3D3;
                          }
                  
                          i {
                              padding-right: 30px;
                          }
          }


}
@media screen and (max-width: 944px){
    .sidebar{
        width: 100vw;
    }
}
