.icon-h4 svg{
  font-size: 40px;

}
.center{
  text-align: center;
}
.inline-center{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.show {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto;
  transition: all 1s ease-in-out;
  overflow: hidden;

  &.hide {
            height: 0;
            overflow: hidden;
  }
}
.text{
  color: var(--clr-black);
}
.text-secondary{
  color: var(--secondary-text-color);
}
.right-after-header{
  position: relative;
  top: 70px;
}
// this qurery will hide all no printable elements
@media print {
  .no-print {
    display: none !important;
  }
}
// this qurery will hide all no printable elements

.urdu {
  font-family: "Jameel Noori Nastaleeq","JNNastaleeq" !important;
}

.arabic {
  font-family: NHuda !important;
  font-weight: 550 !important;
}

.arabic1 {
  font-family: Attari_Quran, _PDMS_Saleem_QuranFont !important;
  font-weight: 550 !important;
  font-style: bold;
}

.primary-5 {
  background-color: var(--clr-primary-5);
  color: var(--clr-white);
}
.quran-marker {
  font-family: "noorehuda","NHuda","Times New Roman";
  font-size: 20px;
  line-height: 27px;
  color: Blue;
}

.quran-bold-marker {
  font-family: "noorehuda","NHuda", "Times New Roman";
  font-size: 20px;
  line-height: 27px;
  color: Blue;
  font-weight: bold;
}

.quran-underline-marker {
  font-size: 20px;
  line-height: 27px;
  color: Blue;
  text-decoration: underline;
}

.hadees-marker {
  font-family: "noorehuda","NHuda", "Times New Roman";
  font-size: 20px;
  line-height: 27px;
  color: Green;
}

.hadees-underline-marker {
  font-family: "noorehuda","NHuda", "Times New Roman";
  font-size: 20px;
  line-height: 27px;
  color: Green;
  text-decoration: underline;
}

.hadees-bold-marker {
  font-family: "noorehuda","NHuda", "Times New Roman";
  font-size: 20px;
  line-height: 27px;
  color: Green;
  font-weight: bold;
}

.urdu-marker {
  font-family: "Jameel Noori Nastaleeq","JNNastaleeq" !important;
  font-size: 20px;
  word-spacing: 1px;
  line-height: 32px;
  color: Black;
}
.urdu-paragraph {
  font-family: "Jameel Noori Nastaleeq","JNNastaleeq" !important;
  font-size: 16px;
  line-height: 32px;
  text-align: justify;
  color: Black;
}

.urdu-underline-marker {
  font-family: "Jameel Noori Nastaleeq","JNNastaleeq" !important;
  font-size: 16px;
  color: Black;
  text-decoration: underline;
}

.urdu-bold-marker {
  font-family: "Jameel Noori Nastaleeq","JNNastaleeq" !important;
  font-size: 13px;
  color: Black;
  font-weight: bold;
}

.ref-urdu-marker {
  font-family: "Jameel Noori Nastaleeq","JNNastaleeq" !important;
  font-size: 13px;
  color: Gray;
  display: block;
  text-align: center;
}

.ref-urdu {
  font-family: "Jameel Noori Nastaleeq","JNNastaleeq" !important;
  font-size: 14px;
  color: Gray;
  display: block;
  text-align: center;
}

.arabic-marker {
  font-family: "noorehuda","NHuda", "Times New Roman";
  font-size: 20px;
  line-height: 27px;
  color: Maroon;
}

.arabic-underline-marker {
  font-family: "noorehuda","NHuda", "Times New Roman";
  font-size: 20px;
  line-height: 27px;
  color: Maroon;
  text-decoration: underline;
}

.arabic-bold-marker {
  font-family: "noorehuda","NHuda", "Times New Roman";
  font-size: 20px;
  line-height: 27px;
  color: Maroon;
  font-weight: bold;
}

.ref-arabic-marker {
  font-family: "noorehuda","NHuda", "Times New Roman";
  color: Gray;
  font-size: 14px;
}

.ref-arabic {
  font-family: "noorehuda","NHuda", "Times New Roman";
  color: Gray;
  font-size: 14px;
}

.english-marker {
  font-family: Times New Roman;
  color: #bb8626;
  font-size: 18px;
}

.english-underline-marker {
  font-family: Times New Roman;
  color: #bb8626;
  font-size: 18px;
  text-decoration: underline;
}

.english-bold-marker {
  font-family: Times New Roman;
  color: #bb8626;
  font-size: 18px;
  font-weight: bold;
}

.ref-english-marker {
  font-family: Times New Roman;
  color: Gray;
  font-size: 14px;
}

.ref-english {
  font-family: Times New Roman;
  color: Gray;
  font-size: 14px;
}

.heading-marker {
  display: block;
  font-family: "MehrNastaliqWeb" !important;
  font-size: 30px;
  color: navy;
  text-align: center;
}

.heading1-marker {
  font-family: "MehrNastaliqWeb" !important;
  font-size: 24px;
  color: navy;
  text-align: center;
}

.heading-underline-marker {
  display: block;
  font-family: "MehrNastaliqWeb" !important;
  font-size: 24px;
  color: navy;
  text-align: center;
  text-decoration: underline;
  /*line-height: 60px;*/
}

.chapter-marker {
  font-family: "Jameel Noori Nastaleeq","JNNastaleeq" !important;
  font-size: 50px;
  color: navy;
  display: block;
  text-align: center;
  line-height: 70px;
}

.poetry-marker {
  font-family: "Jameel Noori Nastaleeq","JNNastaleeq" !important;
  font-size: 20px;
  color: #f20b0b;
  text-align: center;
}

.margin-line-marker {
  display: block;
  text-align: center;
}