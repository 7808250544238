.content {
    width: calc(100% - 250px);
    margin-top: 60px;
    padding: 20px;
    margin-left: 250px;
    background-position: center;
    background-size: cover;
    height: 100vh;
    transition: 0.5s;
}

@media screen and (max-width: 944px) {
    .sidebar {
        left: -100vw;
        overflow: hidden;
        width: 100vw;
        overflow: scroll;
    }

    .content {
        margin-left: 60px;
        width: calc(100vw - 60px);

    }
}