/*
=============== 
Variables
===============
*/

:root {
          /*my primary colors */
          --primary-1: #1877f2;
          --primary-2: rgb(10, 131, 212);
          --dark1: #22242A;
          /* dark shades of primary color*/
          --black-transparent: rgba(0, 0, 0,0.6);
          --clr-primary-1: hsl(205, 86%, 17%);
          --clr-primary-2: hsl(205, 77%, 27%);
          --clr-primary-3: hsl(205, 72%, 37%);
          --clr-primary-4: hsl(205, 63%, 48%);
          /* primary/main color */
          --clr-primary-5: hsl(205, 78%, 60%);
          /* lighter shades of primary color */
          --clr-primary-6: hsl(205, 89%, 70%);
          --clr-primary-7: hsl(205, 90%, 76%);
          --clr-primary-8: hsl(205, 86%, 81%);
          --clr-primary-9: hsl(205, 90%, 88%);
          --clr-primary-10: hsl(205, 100%, 96%);
          /* darkest grey - used for headings */
          --clr-grey-1: hsl(209, 61%, 16%);
          --clr-grey-2: hsl(211, 39%, 23%);
          --clr-grey-3: hsl(209, 34%, 30%);
          --clr-grey-4: hsl(209, 28%, 39%);
          /* grey used for paragraphs */
          --clr-grey-5: hsl(210, 22%, 49%);
          --secondary-text-color: #737373;
          --clr-grey-6: hsl(209, 23%, 60%);
          --clr-grey-7: hsl(211, 27%, 70%);
          --clr-grey-8: hsl(210, 31%, 80%);
          --clr-grey-9: hsl(212, 33%, 89%);
          --clr-grey-10: hsl(210, 36%, 96%);
          --clr-white: #fff;
          --clr-red-dark: hsl(360, 67%, 44%);
          --clr-red-light: hsl(360, 71%, 66%);
          --clr-green-dark: hsl(125, 67%, 44%);
          --clr-green-light: hsl(125, 71%, 66%);
          --clr-black: #222;
          --transition: all 0.3s linear;
          --spacing: 0.1rem;
          --radius: 0.25rem;
          --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
          --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
          --max-width: 1170px;
          --fixed-width: 620px;
          --shadow2:  rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
          // adding fonts
          --poppin: 'Poppins', sans-serif;
          --roboto: 'Roboto', sans-serif;
        }