@import './assets/scss/_editor.scss';
@import './App';
@import './assets/scss/globalClasses';
@import './assets/scss/variables';
@import './assets/scss/fonts';
@import './assets/scss/preloader';
@import './assets/scss/profile_dropdown';
@import './assets/scss/singleBook';
@import './assets/scss/login';
@import './assets/scss/header';
@import './assets/scss/sidebar';
@import './assets/scss/dashboardSingleBook';
@import './assets/scss/content';
@import './assets/scss/sidebarToggle';
@import './assets/scss/popup';
@import './assets/scss/buttons/verse1';
@import './assets/scss/inputByVerse';
@import './assets/scss/select';
@import './assets/scss/myBooks';
@import './assets/scss/contextMenu';
@import './assets/scss/singlePage';
@import './assets/scss/deletePopup';
@import './assets/scss/miniLoader';
@import './assets/scss/buttons/btnByVerse2';
@import './assets/scss/frontend/mainPage';
@import './assets/scss/frontend/allMatch';
@import './assets/scss/frontend/singleMatch';
@import './assets/scss/bottomBar';
@import './assets/scss/sharePopup';
@import './assets/scss/adminControls';
@import './assets/scss/dropDownMenu';

@import url("https://use.fontawesome.com/releases/v5.7.2/css/all.css");



/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}
body::-webkit-scrollbar-button{
  display: none;
}
body {
  overflow-x: hidden;
  font-family: "Roboto", sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-grey-10);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}
.popup-content-container::-webkit-scrollbar{
  display: none;
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

